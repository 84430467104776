import { getSportIcon } from './getSportIcon'

interface MenuItem {
  id: number
  type: 'tournament' | 'sport' | 'category' | 'custom'
  countryCode?: string
  sportId?: number
  categoryId?: number
}

export interface MenuIcon {
  type: 'sport' | 'flag' | 'cup'
  name: string
}

export interface GetMenuItemIconOptions {
  /** Пункт меню (спорт/категория/турнир) */
  item: MenuItem
  /** Уровень вложенности (0 - корень, 1 - дочерние элементы корня и тд) */
  level: number
}

/**
 * Получить иконку для пункта меню
 */
export function getMenuItemIcon({
  item,
  level,
}: GetMenuItemIconOptions): MenuIcon | null {
  if (level === 0) {
    return {
      name: getSportIcon({
        sportId: item.sportId ?? item.id,
        categoryId:
          item.categoryId ?? item.type === 'category' ? item.id : undefined,
        tournamentId: item.type === 'tournament' ? item.id : undefined,
      }),
      type: 'sport',
    }
  } else if (level === 1 && item.countryCode) {
    return {
      name: `f-${item.countryCode.toLowerCase()}`,
      type: 'flag',
    }
  } else if (level === 1 && !item.countryCode) {
    return {
      name: 'cup-solid',
      type: 'cup',
    }
  } else {
    return null
  }
}
